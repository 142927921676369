<template>
    <div class="login_box">
        <loading-component></loading-component>
        <div class="login_form_div">
            <img
                class="login_img"
                src="../../assets/img/login/login_avatar.jpg"
            />
        </div>
        <div class="login_title">
            武汉宇圣通办公管理系统
        </div>
        <van-form>
            <van-field
                v-model="user.username"
                name="username"
                label="用户名"
                placeholder="用户名"
                :rules="[{ required: true, message: '请填写用户名' }]"
            />
            <van-field
                v-model="user.password"
                type="password"
                name="password"
                label="密码"
                placeholder="密码"
                :rules="[{ required: true, message: '请填写密码' }]"
            />
            <div class="login_button">
                <van-button round block type="info" @click="onLogin">登 录</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>

import LoadingComponent from '@/components/common/LoadingComponent.vue'

import { staff_login_request } from '@/network/user.js'

export default {
    name: 'LoginView',
    data() {
        return {
            user: { 
                username: 'admin',
                password: 'Qq123456.',
            }
        }
    },
    computed: {},
    methods: {
        onLogin() {
            this.$store.commit('true_loading')
            staff_login_request(this.user)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$toast.success('登录成功');
                            this.$router.replace('/')
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => this.$store.commit('false_loading'))
        },
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        LoadingComponent
    },
}
</script>
<style scoped>
.login_form_div {
    text-align: center;
}
.login_img {
    width: 3rem;
    height: 3rem;
    border-radius:50%
}
.login_box{
    width: 375px;
    border-radius: 3px;
    position: absolute;
    top: 25%;
    /* transform: translate(-50%,-50%); */
    opacity: 0.9;
}
.login_title {
    margin: 0.5rem 0;
    font-size: 0.7rem;
    text-align: center;
    font-weight: bold;
}
.login_button {
    margin: 0.5rem;
}
</style>